.call-interface {
  z-index: 1080 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 18em;
  height: 16em;
  margin: auto;
}

.call-action {
  cursor: pointer;
}
