.h-90 {
  height: 90%;
}

.w-2 {
  width: 2rem;
}

.w-1 {
  width: 1rem;
}

.text-sm {
  font-size: 0.9rem;
}

.text-xs {
  font-size: 0.8rem;
}

.text-brand-secondary {
  color: $brand-secondary;
}

@media only screen and (max-width: 767px) {
  .w-2 {
    width: 1.5rem;
  }

  .text-sm {
    font-size: 0.8rem;
  }

  .text-xs {
    font-size: 0.7rem;
  }

  .h-90 {
    height: 85%;
  }
}

.cursor-pointer {
  cursor: pointer;
}

$primary-color: rgb(125, 125, 125);
$error-color: rgb(247, 165, 165);
$animation-duration: 0.4s;

@mixin label-active() {
  font-size: 0.75rem;
  transform: translateY(-1.5rem);
}

.form-field {
  position: relative;
  display: block;
  margin-bottom: 1rem;

  &--is-active {
    .form-field__control {
      &::after {
        border-bottom: 2px solid $primary-color;
        transform: scaleX(150);
      }
    }
    .form-field__label {
      color: $primary-color;
      @include label-active();
    }

    .form-field__leading {
      top: 1.2rem;
    }
  }

  &--is-filled {
    .form-field__label {
      @include label-active();
    }
  }
}

.form-field__label {
  position: absolute;
  top: 1rem;
  left: 0;
  display: block;
  width: 100%;
  padding: 1rem 2rem 0;
  margin: 0;
  font-weight: normal;
  transition: all $animation-duration;
}

.form-field__control {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: transparent;
  border-radius: 1rem 1rem 0 0;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 1%;
    border-bottom: 2px solid $primary-color;
    margin: 0 auto;
    content: '';
    transform: scaleX(0);
    transition: all $animation-duration;
  }
}

.form-field__input,
.form-field__select {
  display: block;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.8rem;
  padding-left: 2rem;
  border: 0;
  border-bottom: 1px solid $primary-color;
  margin-top: 1rem;
  appearance: none;
  background: transparent;
  outline: 0;
}

.form-field__leading {
  position: absolute;
  top: 2rem;
  width: 1.5rem;
  height: 1.5rem;
}

.form-field__ending {
  position: absolute;
  top: 2rem;
  right: 0;
  width: 1.8rem;
  height: 1.8rem;
  fill: rgb(114, 202, 223);
}

.form-field__input_error {
  border-bottom: 1px solid $error-color;
}

.form-button__submit {
  margin: 1rem 1rem;
  background-color: rgb(45, 176, 208);
  border-radius: 2rem;
  color: #ffffff;

  &:hover {
    background-color: rgb(45, 176, 208);
  }

  &:disabled {
    background-color: gray;
    color: #ffffff;
    cursor: not-allowed;
  }
}

.icon-sm {
  width: 1.2rem;
}
