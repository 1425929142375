@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: local('Poppins'), url(./Assets/Fonts/Poppins/Poppins-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: local('Poppins'),
    url(./Assets/Fonts/Poppins/Poppins-LightItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins'), url(./Assets/Fonts/Poppins/Poppins-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: local('Poppins'), url(./Assets/Fonts/Poppins/Poppins-Italic.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local('Poppins'), url(./Assets/Fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: local('Poppins'),
    url(./Assets/Fonts/Poppins/Poppins-BoldItalic.ttf) format('truetype');
}
@import 'Styles/variables';
@import 'Styles/pages';
@import 'Styles/utilities';

@import 'bootstrap/scss/bootstrap';

@import 'Styles/Blocks/avatar';
@import 'Styles/Blocks/_patientAvatar';

@import 'Styles/Compositions/Consultations/consultation_chat_list';
@import 'Styles/Compositions/Consultations/consultation_chat_box';
@import 'Styles/Compositions/Consultations/consultation_chat_write';
@import 'Styles/Compositions/Consultations/consultation_chat_profile_header';
@import 'Styles/Compositions/Consultations/consultation_timer';
@import 'Styles/Compositions/Consultations/consultation_spinner';

@import 'Styles/Compositions/Settings/settings';

@import 'Styles/Compositions/Privacy/privacy';
@import 'Styles/Compositions/Help/help';
@import 'Styles/Compositions/PatientSignUp/patientSignUp';

@import 'Styles/Compositions/PatientPortal/PatientPortal';
@import 'Styles/Compositions/Voice/voice.scss';

@import 'Styles/Blocks/_header';

@import 'Styles/Compositions/Patients/patientList.scss';
@import 'Styles/Compositions/Tables/DiabetesTable.scss';

@import 'Styles/Compositions/SplashScreens/SplashScreen.scss';

@function calculateRem($size) {
  @return $size / 16px * 1rem;
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

html,
#root,
main {
  height: 100vh;
}

body {
  overflow: hidden;
}

.h-100 {
  height: 100vh !important;

  &.container-fluid {
    padding: 0 !important;
  }
}
.h-100-percentage {
  height: 100%;
}
.content {
  padding-top: 55px;
}

[data-notifications] {
  position: relative;
}

[data-notifications]:after {
  position: absolute;
  top: -8px;
  right: -40px;
  display: inline-block;
  width: 25px;
  height: 25px;
  background: red;
  border-radius: 50%;
  color: #f2f2f2;
  content: attr(data-notifications);
}

.validation-error-text {
  color: red;
  font-size: 0.7rem;
}

.validation-error-input {
  border-color: red;
}
