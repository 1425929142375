.gradient {
  height: calc(var(--vh, 1vh) * 90);
  background-image: linear-gradient(to bottom left, #2844b2, #4cc8e7);
}

.header-gradient {
  border-bottom: none;
  background-image: linear-gradient(to right, #3b87cd, #2945b3);

  button {
    color: white;
  }

  button:hover {
    color: white;
  }
}

.hex-splash {
  position: relative;
  display: inline-block;
  width: 30vw;
  height: 15vw;
  border-color: var(--background);
  margin-top: 35%;
  background-color: var(--background);
}

.hex-splash:before {
  position: absolute;
  top: -7.5vw;
  right: 0rem;
  width: 0;
  height: 0;
  border-color: var(--background);
  border-right: 15vw solid transparent;
  border-bottom: 7.5vw solid var(--background);
  border-left: 15vw solid transparent;
  content: ' ';
}

.hex-splash:after {
  position: absolute;
  right: 0rem;
  bottom: -7.5vw;
  width: 0;
  border-color: var(--background);
  border-top: 7.5vw solid var(--background);
  border-right: 15vw solid transparent;
  border-left: 15vw solid transparent;
  content: '';
}

.hex-splash-p {
  color: #fff;
  font-size: 6vw;
  font-weight: bold;
  line-height: 7vw;
}

.large-text-container {
  width: 32vw;
  height: inherit;
  margin-left: 7vw;
  color: #fff;
}

.small-text-container {
  width: 29vw;
  height: inherit;
  margin-left: -10vw;
  color: #fff;
}

.hex-splash-small {
  position: relative;
  display: inline-block;
  width: 18vw;
  height: 9vw;
  border-color: var(--background);
  margin-top: 30px;
  background-color: var(--background);
}

.hex-splash-small:before {
  position: absolute;
  top: -4.5vw;
  right: 0rem;
  width: 0;
  height: 0;
  border-color: var(--background);
  border-right: 9vw solid transparent;
  border-bottom: 4.5vw solid var(--background);
  border-left: 9vw solid transparent;
  content: ' ';
}

.hex-splash-small:after {
  position: absolute;
  right: 0rem;
  bottom: -4.5vw;
  width: 0;
  border-color: var(--background);
  border-top: 4.5vw solid var(--background);
  border-right: 9vw solid transparent;
  border-left: 9vw solid transparent;
  content: '';
}

.double-hexagon-left {
  margin-left: 10vw;
}

.double-hexagon-right {
  margin-right: 10vw;
  float: right;
}

.lightText {
  overflow: hidden;
  color: #fff;
  font-size: 2vw;
  font-style: normal;
  font-weight: 100;
  letter-spacing: 1px;
  line-height: 2vw;
  text-overflow: ellipsis;
}

.hex-spacing {
  height: 65vh;
  margin-top: 1rem;
  margin-right: 5rem;
  margin-left: 5rem;
}

.sideComponentTextContainer {
  width: 45%;
  justify-content: space-between;
}

.sideComponentButton {
  width: 45%;
  justify-content: flex-end;
}

.description {
  display: hide;
  overflow: hidden;
  color: #fff;
  font-size: 2.5vmin;
  font-style: normal;
  font-weight: 100;
  letter-spacing: 1px;
  line-height: 3vmin;
  text-overflow: ellipsis;
  white-space: pre-line;
}

.footnote {
  color: #fff;
  font-size: 0.8rem;
  font-weight: 100;
}

.callBar {
  width: 40vmin;
}

.CallSquare,
.callInterface {
  width: 20vmin;
}

.button-margin {
  margin-top: 3rem;
}

.nextButton {
  width: 20vw;
  border: none;
  margin-right: 3rem;
  background: #4cc8e7;
  border-radius: 19px;
  float: right;
}

.nextButton:active {
  background-color: #4cc8e7 !important;
}

.nextButton:hover {
  border-color: #4cc8e7 !important;
  background-color: #4cc8e7 !important;
}

.backButton {
  width: 20vw;
  border: none;
  margin-right: 3rem;
  background: #fa9f71;
  border-radius: 19px;
  float: right;
}

.backButton:active {
  background: #fa9f71 !important;
}

.backButton:hover {
  border-color: #fa9f71 !important;
  background: #fa9f71 !important;
}

.finishButton {
  width: 20vw;
  border: none;
  margin-right: 3rem;
  background: #1a936f;
  border-radius: 19px;
  float: right;
}

.callButton {
  height: 50%;
  margin-left: 1vw;
  background: #1a936f;
  border-radius: 10px;
}

.container {
  position: relative;
  display: block;
  padding-top: 0.5rem;
  padding-left: 5rem;
  margin-bottom: 1rem;
  color: #fff;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  #text {
    margin-left: 2rem;
  }
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  width: 0;
  height: 0;
  cursor: pointer;
  opacity: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 3rem;
  height: 3rem;
  background-color: #eee;
  border-radius: 0.6rem;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  position: absolute;
  display: none;
  content: '';
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  top: 0.1rem;
  left: 1rem;
  width: 1rem;
  height: 2rem;
  border: solid #000;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.lg-fullscreen {
  max-width: 90%;
}

.splash-modal {
  position: relative;
  padding: 0rem;
}

.logo {
  width: 3vw;
}

.medical {
  width: 5.5vw;
  color: #fff;
}

.callInput {
  width: 23rem;
  padding: 0.2rem 1.5rem;
  border: none;
  margin-bottom: 3rem;
  border-radius: 19px;
  outline: none;
}

.hex-splash-small-p {
  margin-left: 2.5vw;
  color: #fff;
  font-size: 5.5vw;
  font-weight: bold;
  line-height: 7vw;
}

.close-button {
  position: absolute;
  right: 0px;
  padding-right: 1rem !important;
  color: white;
  font-size: 2.5rem;
  opacity: 0.7;
}

.close-button:hover {
  color: white;
  opacity: 1 !important;
}

@media only screen and (max-width: 768px) {
  .gradient {
    height: calc(var(--vh, 1vh) * 90);
  }

  .hex-splash {
    width: 50vw;
    height: 25vw;
    margin-top: 45%;
  }

  .hex-splash:before {
    top: -12.5vw;
    border-right: 25vw solid transparent;
    border-bottom: 12.5vw solid var(--background);
    border-left: 25vw solid transparent;
    content: ' ';
  }

  .hex-splash:after {
    bottom: -12.5vw;
    border-top: 12.5vw solid var(--background);
    border-right: 25vw solid transparent;
    border-left: 25vw solid transparent;
  }

  .hex-splash-p {
    margin-left: 1.8rem;
    font-size: 10vw;
    font-weight: bold;
    line-height: 12vw;
  }

  .large-text-container {
    width: 32vw;
    height: inherit;
    margin-left: 8vw;
  }

  .small-text-container {
    width: 32vw;
    height: inherit;
    margin-left: -14vw;
  }

  .lightText {
    font-size: 2vw;
    line-height: 3vw;
  }

  .hex-splash-small {
    position: relative;
    display: inline-block;
    width: 24vw;
    height: 14vw;
    border-color: var(--background);
    margin-top: 30px;
    background-color: var(--background);
  }

  .hex-splash-small:before {
    top: -6vw;
    border-right: 12vw solid transparent;
    border-bottom: 6vw solid var(--background);
    border-left: 12vw solid transparent;
    content: ' ';
  }

  .hex-splash-small:after {
    bottom: -6vw;
    border-top: 6vw solid var(--background);
    border-right: 12vw solid transparent;
    border-left: 12vw solid transparent;
  }

  .hex-spacing {
    height: 80vh;
    margin-top: 1rem;
    margin-right: 5rem;
    margin-left: 5vw;
  }

  .sideComponentTextContainer {
    width: 63vw;
    justify-content: center;
    margin-left: 10vw;
  }

  .sideComponentButton {
    width: 100%;
    align-items: center;
  }

  .description {
    display: hide;
    font-size: 2.5vw;
    line-height: 1.5rem;
  }

  .footnote {
    font-size: 0.6rem;
    font-weight: 100;
  }

  .callBar {
    width: 35vmin;
  }

  .CallSquare,
  .callInterface {
    width: 15vmin;
  }

  .nextButton {
    width: 30vw;
    border: none;
    margin-right: 3rem;
    float: right;
  }

  .backButton {
    width: 30vw;
    border: none;
    margin-right: 3rem;
    float: right;
  }

  .finishButton {
    width: 30vw;
    border: none;
    margin-right: 3rem;
    float: right;
  }
}

@media only screen and (max-width: 480px) {
  .gradient {
    height: 100vh;
  }

  .hex-spacing {
    height: 80vh;
    margin-top: 1rem;
    margin-right: 5rem;
    margin-left: 5vw;
  }

  .double-hexagon-left {
    margin-left: 5vw;
  }

  .double-hexagon-right {
    margin-right: 5vw;
  }

  .button-margin {
    margin-top: 1rem;
  }

  .nextButton {
    width: 30vw;
  }

  .backButton {
    width: 30vw;
  }

  .finishButton {
    width: 30vw;
  }

  .sideComponentTextContainer {
    width: 80vw;
    margin-left: 1vw;
  }

  .callBar {
    width: 50vw;
  }

  .CallSquare,
  .callInterface {
    width: 30vw;
  }

  .callInput {
    width: 60vw;
  }

  .container {
    padding-top: 0;
    padding-left: 10vw;
    font-size: 4vw;

    #text {
      margin-left: 2rem;
    }
  }

  /* Create a custom checkbox */
  .checkmark {
    width: 8vw;
    height: 8vw;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    top: 1vw;
    left: 2vw;
    width: 3vw;
    height: 6vw;
  }

  .hex-splash-small-p {
    margin-top: 1vh;
  }

  .logo {
    width: 10vw;
  }

  .medical {
    width: 17vw;
  }
}
