.table-container {
  margin: 0 auto;
  background-color: #262b66;
  border-radius: 1rem;
}

.table-container-practitioner {
  margin: 0 auto;
  background-color: #262b66;
  border-radius: 1rem;
}

.table-container-patient {
  margin: 0 auto;
  background-color: #262b66;
  border-radius: 1rem;
}

.row-height {
  height: 2rem;
}
