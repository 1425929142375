.patient-portal-button {
  padding: 0.4rem 1.8rem;
  border: none;
  background-color: #4cc8e7;
  border-radius: 1rem;
  box-shadow: none !important;
  color: #ffffff;
  font-size: 0.8rem;
  outline: none !important;
}

.patient-portal-tabs {
  background-color: #ffffff;
}

.patient-portal-tabs .nav-item.active {
  background-color: #2943b3 !important;
  color: #ffffff;
}

.button-display-hide {
  display: none !important;
}
