.consultation-chat-profile-header {
  display: flex;
  width: 90%;
  height: 75px;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1%;
  margin-left: 5%;
  background: $white;

  .col-one {
    display: flex;
    margin-left: 5%;
  }

  .col-two {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: auto;
    margin-left: 5%;

    h3 {
      margin-bottom: 0rem;
    }

    .patient-details {
      font-size: 0.8rem;
      font-weight: 500;

      span {
        margin-right: 0.6rem;
      }
    }
  }

  .col-three {
    display: grid;
    align-content: center;

    button {
      width: 90%;
    }
  }

  .col-four {
    display: grid;
    align-content: center;

    button {
      width: 90%;
    }
  }
}

.paid-consultation {
  padding-right: 1rem;
  color: green;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .consultation-chat-profile-header {
    justify-content: flex-end;

    h3 {
      font-size: 0.9rem;
    }

    .col-one {
      display: none;
    }

    .col-two {
      display: block;
      width: 50%;
      margin: 0;

      h3 {
        font-size: 1.2rem;
        line-height: 1.5rem;
      }

      .patient-details {
        font-size: 0.7rem;
        font-weight: 500;
      }
    }

    .mobile-button-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .col-three {
        display: block;
        button {
          width: 100%;
          font-size: 0.6rem;
        }
      }

      .col-four {
        display: block;
        button {
          width: 100%;
          font-size: 0.55rem;
        }
      }
    }
  }
}

.options:after {
  padding: 0;
  margin: 0;
  color: gray;
  content: '\2807';
  font-size: 2rem;
}

.options-button {
  height: 100%;
  border: none;
  background: none;
  outline: none;

  &:hover,
  &:focus {
    border: none;
    background: none;
    outline: none !important;
  }
}
