.consultation-chat-list .active {
  background: $body-bg;
  color: $body-color !important;
  .consultation-chat-list-col3 {
    color: $body-color;
  }
  .chat-last-message {
    color: $text-muted !important;
  }
}

.consultation-list-box {
  &:hover {
    background: $body-bg;
    color: $body-color !important;
    .consultation-chat-list-col3 {
      color: $body-color;
    }
    .chat-last-message {
      color: $text-muted !important;
    }
  }

  z-index: 10;
  display: grid;
  height: 80px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background: rgb(255, 255, 255);
  cursor: pointer;
  grid-template-columns: 20% 55% 25%;
  overflow-wrap: break-word;
  pointer-events: all;
  user-select: none;

  .consultation-chat-list-col1 {
    display: grid;
    align-content: end;
    grid-template-columns: 100%;
  }

  .consultation-chat-list-col2 {
    width: 100%;
    align-self: center;
    justify-self: start;

    .chat-last-message {
      overflow: hidden;
      width: 160px;
      margin: 0px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.9em;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .consultation-chat-list-col3 {
    display: grid;
    width: 100%;
    height: 50%;
    align-self: center;
    color: rgba(0, 0, 0, 0.4);
    font-size: 0.8em;

    span {
      color: #000000;
      font-weight: 500;
    }
  }
}

.active {
  background-color: $body-bg;
}

@media only screen and (max-width: 767px) {
  .consultation-chat-list {
    overflow: hidden;
    width: 100%;
    margin-top: 0.5rem;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .consultation-chat-list::-webkit-scrollbar {
    display: none;
  }
}
