.consultation-chat-write {
  position: relative;
  display: flex;
  height: 6rem;
  flex-direction: row;
  flex-shrink: 0;
  padding: 1rem;

  textarea {
    flex-grow: 1;
    margin-right: 1rem;
    background-color: white;

    &:focus {
      border-color: $brand-secondary;
      box-shadow: none !important;
    }

    @include media-breakpoint-between(xs, sm) {
      width: 100%;
    }
  }

  .sendButton {
    width: 8%;
    border-color: #007bff;
    background-color: #007bff;
    border-radius: 0.6rem;
    box-shadow: none !important;
    color: #ffffff;
  }
}

@media only screen and (max-width: 767px) {
  .consultation-chat-write {
    width: 100%;
    padding: 0.5rem;

    .sendButton {
      width: 25%;
    }
  }
}
