.custom-icon-name {
  display: flex;
  flex-direction: row;

  p {
    padding-top: 0.3rem;
    margin-bottom: 0;
    font-size: 1rem;
  }
}

.dropdown-button {
  border: none;
  margin-top: 0.2rem;
  background: none !important;
  box-shadow: none !important;
  font-weight: 300;

  &:focus {
    background: none !important;
    box-shadow: none !important;
    outline: none;
  }

  &:active {
    background: none !important;
    box-shadow: none !important;
    outline: none;
  }
}

@media only screen and (max-width: 767px) {
  .dropdown-button {
    padding: 0;
  }
}
