.consultation-page {
  .nav-link {
    border: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .nav-item {
    cursor: pointer;
  }
  .nav-tabs .nav-link {
    padding-top: 1rem;
    padding-bottom: 0.8rem;

    &:hover {
      background: $brand-secondary;
      color: $white;
    }
  }

  .nav-tabs {
    border-bottom: 0 !important;
  }

  .nav-tabs .nav-item {
    margin-bottom: 0;
  }

  .nav-link.active {
    background-color: $brand-secondary !important;
    color: $white !important;
  }

  .consultation-page-left {
    overflow: hidden;
    height: 100%;
    background: white;
    overflow-y: scroll;

    .nav-tabs {
      position: sticky;
      z-index: 100;
      top: 0;
      border-bottom: 0 !important;
      background: white;
    }
  }

  .consultation-page-right {
    height: 90vh;

    .consultation-chat-area {
      position: relative;
      display: flex;
      height: 100%;
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 767px) {
  .consultation-page-left {
    position: fixed !important;
  }
  .consultation-chat-area {
    position: fixed;
    display: flex;
    width: 100%;
    flex-direction: column;

    .consultation-header-button-region {
      display: inline-flex;
      height: inherit;
      padding: 0 0.5rem 0 0.5rem;
      margin-top: 0.6rem;

      button {
        height: fit-content;
      }
    }
  }
}

.bg-light-gray {
  background-color: rgb(244, 244, 244);
}

.bg-gray {
  background-color: rgb(247, 247, 247);
}

.button-reset {
  overflow: visible;
  width: auto;
  padding: 0;
  border: none;
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  color: inherit;
  font: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  line-height: normal;
}

.button-reset:hover,
.button-reset:focus {
  background: rgb(244, 244, 244);
  outline: none;
}

.consultation-status {
  padding: 0.4rem 0.8rem;
  background-color: $brand-secondary;
  border-radius: 1rem;
  color: $white;

  select {
    border: none;
    margin: 0;
    background-color: transparent;
    cursor: inherit;
    font-family: inherit;
    font-size: 0.8rem;
    line-height: inherit;

    &:focus {
      outline: none;
    }
  }
}
