.help-container {
  width: 50%;
  height: 100%;
  padding: 1em;
  margin: 0 auto;
  background-color: white;
  border-radius: 1rem;
  overflow-y: scroll;
}

@media only screen and (max-width: 767px) {
  .help-container {
    width: 100%;
    margin: 0;
  }
}
