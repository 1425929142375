.consultation-timer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: black;

  span {
    margin-right: 0.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .consultation-timer {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    width: 50%;
  }
}
