$font-family-base: 'Poppins', sans-serif;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

$font-weight-base: $font-weight-light !default;

$white: #ffffff;

$brand-primary: #2b2f8e;
$brand-secondary: rgb(53, 131, 234);

$body-bg: #f6f6fc;
$body-color: #555555;
$text-muted: #888888;
$input-btn-focus-color: $body-color;

$dark: $brand-primary;
$navbar-dark-color: $white;
$navbar-dark-hover-color: $white;
