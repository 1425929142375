.consultation-chatbox {
  overflow-y: scroll !important;

  .message-time {
    font-size: 0.7rem;
  }

  .message {
    position: relative;
    max-width: 300px;
    padding: 0.5em;
    margin: 1em 0;
    font-size: 0.8rem;

    p {
      padding: 0;
      margin: 0;
    }
  }
  .message.patient {
    margin-left: 2rem;
    background: white;

    .chat-name {
      font-weight: 400;

      .chat-hr {
        margin: 1px 0 5px 0;
      }
    }
  }

  .message.server {
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  .message.practitioner {
    margin-right: 1rem;
    background: $brand-secondary;
    color: $white;

    &.queued {
      opacity: 0.5;
    }
    .chat-name {
      font-weight: 400;

      .chat-hr {
        margin: 1px 0 5px 0;
      }
    }
    .message-time {
      color: darken($white, 20%);
    }
  }

  .message.multiple_practitioner {
    margin-left: 2rem;
    background: #00d1d4;
    color: $white;

    &.queued {
      opacity: 0.5;
    }
    .chat-name {
      font-weight: 400;

      .chat-hr {
        margin: 1px 0 5px 0;
      }
    }
    .message-time {
      color: darken($white, 20%);
    }
  }

  .message_float_right {
    flex-shrink: 0;
    justify-content: flex-end;
  }

  .text-center-flex {
    display: flex;
    justify-content: center;
  }

  .message_float_left {
    flex-shrink: 0;
    justify-content: flex-start;
  }

  .message_float_center {
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .consultation-chatbox {
    display: flex;
    flex-direction: column;
    padding: 0rem 1rem 0rem 1rem;
    overflow-y: scroll;

    .message.patient {
      margin-left: 0rem;
    }

    .message.multiple_practitioner {
      margin-left: 0rem;
    }

    .message.practitioner {
      margin-right: 0rem;
    }
  }
}
