@import '~antd/dist/antd.css';

.form-layout {
  height: 100%;
  overflow-y: scroll;
}
.patient-heading {
  text-align: center;
}

.ant-select-selection-item {
  flex: none;
}

.steps-content {
  flex: 1;
  padding-top: 10px;
  border: 1px dashed #e9e9e9;
  margin-top: 16px;
  background-color: #fafafa;
  border-radius: 2px;
  text-align: center;

  .map-container .ant-form-item-control-input-content {
    height: 30rem;
  }
}

.steps-action {
  margin-top: 24px;
}

.checkbox {
  justify-content: center;
}

.anticon-check {
  position: relative;
  top: -4px;
}

.ant-radio-wrapper span {
  white-space: pre-line;
}
