.spinner-div {
  display: flex;
  height: inherit;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  width: 5rem !important;
  height: 5rem !important;
  color: $brand-primary;
}
