@media only screen and (min-width: 768px) {
  .settings-page-background {
    height: 100%;
    padding-bottom: 5%;
    background-color: white;
    overflow-y: scroll;

    h3 {
      margin: 1% 0 0 2%;
    }

    hr {
      margin-top: 0;
    }
  }

  .settings-form {
    display: flex;
    width: 80%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    margin-left: 15%;

    h4 {
      width: 80%;
    }

    hr {
      width: 110%;
    }

    .settings-form-input-area {
      width: 80%;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      padding-bottom: 2%;

      &.dynamic {
        width: 85%;
      }

      label {
        width: 16%;
      }

      .settings-form-input {
        width: 80%;
      }

      .settings-form-input-dynamic {
        width: 75%;
      }
    }
  }

  .bttn {
    border: none;
    border-radius: 50%;
    color: white;
    outline: none;

    &.btn-add {
      background-color: $brand-secondary;
    }

    &.btn-submit {
      background-color: $brand-secondary;
      border-radius: 0%;
      float: right;
    }

    &.btn-remove {
      background-color: red;
    }
  }

  .bttn:focus {
    box-shadow: none;
    outline: 0 !important;
  }

  .bttn:hover {
    color: white;
  }

  .submit-bttn-area {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .settings-page-background {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding-bottom: 2rem;
    margin-right: 0rem;
    margin-left: 1rem;
    overflow-y: scroll;

    h3 {
      padding-top: 0.5rem;
    }

    hr {
      margin: 0rem;
    }
  }
  .settings-form-input-area {
    flex-direction: column;

    .settings-form-input {
      width: 80%;
    }

    .settings-form-input-dynamic {
      width: 80%;
    }

    label {
      margin-top: 0.5rem;
      margin-bottom: 0rem;
    }
  }

  .row {
    margin: 0;
  }

  .bttn {
    width: 2rem;
    border: none;
    margin-left: 0.5rem;
    border-radius: 50%;
    color: white;
    outline: none;

    &.btn-add {
      background-color: $brand-secondary;
    }

    &.btn-submit {
      width: 80%;
      margin-top: 1rem;
      margin-left: 0rem;
      background-color: $brand-secondary;
      border-radius: 0%;
    }

    &.btn-remove {
      background-color: red;
    }
  }

  .bttn:focus {
    box-shadow: none;
    outline: 0 !important;
  }

  .bttn:hover {
    color: white;
  }

  .submit-bttn-area {
    margin-top: 1rem;
  }
}
