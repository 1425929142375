.patient-list-container {
  height: calc(100vh - 200px);
  border: 0;
  border-radius: 0;
  cursor: pointer;
  overflow-y: scroll;
}

.hide-patient-list {
  display: none;
}

.active-patient {
  border: none;
  background-color: #2db0d0;
  border-radius: 0;
  color: white;
  cursor: pointer;
}

.active-text {
  color: white;
}

.patient-list-column {
  margin-bottom: 2rem;
  background-color: white;
}

.patient-details-row {
  padding-right: 0.2rem;
  padding-left: 0.2rem;
}

.patient-details-container {
  height: calc(100vh - 55px);
  background-color: #f6f6fc;
  overflow-y: scroll;
}

.patient-meta-data {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.back-button-mobile {
  font-size: 1.5rem;
}

.patient-meta-data-mobile {
  display: flex;
  width: max-content;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
}

.icons-container-mobile {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0.1rem;

  span {
    padding-left: 0.5rem;
  }
}

.icons {
  padding-right: 0.3rem;
  font-size: 1.8rem;
}

.patient-detail-tabs {
  margin-top: 4rem;
}

.patient-detail-tabs-mobile {
  margin-top: 1rem;
}

.patient-nav {
  display: flex;
  height: 3rem;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: white;
}

.active.patientDetailsLink,
.active.patientDetailsLinkMobile {
  height: 3rem;
  background-color: #2db0d0 !important;
  text-align: center;

  span {
    color: white;
  }
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: none;
  outline: none;
}

.nav-link {
  color: black;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: white;
}

.column-mobile {
  padding-right: 0rem;
  padding-left: 0rem;
}

.search-input {
  width: 90%;
  border-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
}

.search-input:focus {
  box-shadow: 0px 0px 0px 1px #2db0d0;
}

.search-group {
  border: none;
  background-color: white;
}

.search-icon {
  position: absolute;
  right: 2.5rem;
}

.modal-form {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;

  .modal-content {
    position: relative;
    width: calc(100vw / 3 * 2);
    align-self: center;
  }
}

.call-button {
  border: none;
  background: none;
  color: blue;
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  .modal-form {
    font-size: 0.8rem;

    .modal-content {
      position: relative;
      width: 100%;
      align-self: center;
    }
  }
}

.patient-details-tabs {
  background-color: $white;

  .nav-link {
    border: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .nav-item {
    cursor: pointer;
  }

  .nav-item,
  .nav-link {
    &:hover {
      background: rgb(54, 176, 206);
      color: $white;
    }
  }

  .nav-tabs {
    border-bottom: 0 !important;
  }

  .nav-tabs.nav-item {
    margin-bottom: 0;
  }

  .nav-item.active {
    background-color: rgb(54, 176, 206) !important;

    .nav-link {
      color: $white !important;
    }
  }
}
