.hex {
  position: relative;
  display: inline-block;
  width: 104px;
  height: 60px;
  border-color: var(--background);
  margin-top: 30px;
  background-color: var(--background);
}
.hex:before {
  position: absolute;
  top: -30px;
  right: 0em;
  width: 0;
  height: 0;
  border-color: var(--background);
  border-right: 52px solid transparent;
  border-bottom: 30px solid;
  border-left: 52px solid transparent;
  content: ' ';
}
.hex:after {
  position: absolute;
  right: 0rem;
  bottom: -30px;
  width: 0;
  border-color: var(--background);
  border-top: 30px solid;
  border-right: 52px solid transparent;
  border-left: 52px solid transparent;
  content: '';
}
